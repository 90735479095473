<template>
  <section>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">จัดการสาขา</h3>
        <div class="card-tools">
          <div class="input-group input-group-sm" style="width: 400px;">
            <input type="text" name="table_search" v-model="search"  v-on:keyup.enter="searchUser()" class="form-control float-right" placeholder="Search">
            <div class="input-group-append">
              <button type="submit" class="btn btn-default" @click.prevent="searchUser()"><i class="fas fa-search"></i></button>
            </div>
            <button class="btn btn-sm btn-primary ml-2" @click.prevent="createModal()"  style=""><i class="fas fa-plus fa-fw"></i>เพิ่ม User </button>
            <button class="btn btn-sm btn-success ml-2" @click.prevent="getResults()"  style=""><i class="fas fa-sync-alt fa-fw"></i></button>
          </div>
        </div>
      </div>
<!--      <div class="card-body">-->
<!--        {{$v}}-->
<!--      </div>-->
      <div class="card-body">
        <div class="row">
          <div class="card-body table-responsive p-0" style="height: 650px;">
            <table class="table table-head-fixed table-hover table-bordered">
              <thead class="thead-light text-center">
              <tr>
                <th scope="col" style="background-color: rgb(197,196,196)">#</th>
                <th v-for="ths in th" :key="ths" style="background-color: rgb(197,196,196)">
                  {{ths}}
                </th>
                <th scope="col" style="background-color: rgb(197,196,196)">Config</th>
              </tr>
              </thead>
                <tbody>
                <tr v-for="(datas,index) in data" :key="datas.id">
                  <th scope="row" style="width: 15px;font-size: 12px">{{index+1}}</th>
                  <td style="font-size: 12px">{{datas.comNameTh }}</td>
                  <td style="font-size: 12px">{{datas.comEmail }}</td>
                  <td style="width: 8%;font-size: 12px">{{datas.comPhone }}</td>
                  <td style="width: 8%;font-size: 12px">{{datas.comCode }}</td>
                  <td style="width: 10%;font-size: 12px">{{datas.createdAt | myDateThs }}</td>
                  <td style="width: 12%;font-size: 12px">
                    <ul style="list-style: none;margin-left: -35px" >
                      <li ><a href="" @click.prevent="editModal(datas,index)"><i class="fas fa-caret-right fa-fw"></i>แก้ไข</a></li>
                      <li ><a href="" @click.prevent="deleteModal(datas,index)"><i class="fas fa-caret-right fa-fw" ></i>ลบ</a></li>
                    </ul>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
        </div>
      </div>
      <div class="card-footer">

      </div>
    </div>
    <!-- /.card -->


    <b-modal size="xl" id="modal-update" ref="modal"
             @ok="update"
             title="แก้ไขรายการ">
      <form ref="form" @submit.prevent="update">
        <div class="form-group col">
          <div class="col">
            <label>ชื่อสาขา</label>
          </div>
          <input
              v-model.trim="$v.form.comNameTh.$model"
              type="text"
              class="form-control"
              placeholder=""
              :class="{ 'is-invalid':$v.form.comNameTh.$error }"
          />
          <span v-if="!$v.form.comNameTh.required " class="invalid-feedback">required</span>
          <span v-if="!$v.form.comNameTh.minLength" class="invalid-feedback">จำนวนตัวอักษรน้อยกว่า 3 ตัวอักษร.</span>
        </div>
        <div class="form-group col">
          <div class="col">
            <label>รหัสสาขา เพื่อออก รหัส ลูกค้า</label>
          </div>
          <input
              v-model.trim="$v.form.comCode.$model"
              type="text"
              class="form-control"
              placeholder=""
              :class="{ 'is-invalid':$v.form.comCode.$error }"
          />
          <span v-if="!$v.form.comCode.required " class="invalid-feedback">required</span>

        </div>
        <div class="form-group col">
          <div class="col">
            <label>Email</label>
          </div>
          <input
              v-model="form.comEmail"
              type="text"
              class="form-control"
              placeholder=""
          />
        </div>
        <div class="form-group col">
          <div class="col">
            <label>เบอร์โทร</label>
          </div>
          <input
              v-model="form.comPhone"
              type="email"
              class="form-control"
              placeholder=""
          />
        </div>
      </form>
    </b-modal>
    <b-modal size="xl" id="modal-create" ref="modal"
             @ok="create"
             title="เพิ่มรายการ">
      <form ref="form" @submit.prevent="create">
        <div class="form-group col">
          <div class="col">
            <label>ชื่อสาขา</label>
          </div>
          <input
              v-model.trim="$v.form.comNameTh.$model"
              type="text"
              class="form-control"
              placeholder=""
              :class="{ 'is-invalid':$v.form.comNameTh.$error }"
          />
          <span v-if="!$v.form.comNameTh.required " class="invalid-feedback">required</span>
          <span v-if="!$v.form.comNameTh.minLength" class="invalid-feedback">จำนวนตัวอักษรน้อยกว่า 3 ตัวอักษร.</span>
        </div>
        <div class="form-group col">
          <div class="col">
            <label>รหัสสาขา เพื่อออก รหัส ลูกค้า</label>
          </div>
          <input
              v-model.trim="$v.form.comCode.$model"
              type="text"
              class="form-control"
              placeholder=""
              :class="{ 'is-invalid':$v.form.comCode.$error }"
          />
          <span v-if="!$v.form.comCode.required " class="invalid-feedback">required</span>

        </div>
        <div class="form-group col">
          <div class="col">
            <label>Email</label>
          </div>
          <input
              v-model="form.comEmail"
              type="text"
              class="form-control"
              placeholder=""
          />
        </div>
        <div class="form-group col">
          <div class="col">
            <label>เบอร์โทร</label>
          </div>
          <input
              v-model="form.comPhone"
              type="email"
              class="form-control"
              placeholder=""
          />
        </div>
      </form>
    </b-modal>
  </section>
</template>

<script>
import axios from 'axios';
import { Form } from 'vform';
import { required, minLength  } from "vuelidate/lib/validators";
export default {
  name: 'company',
  data(){
    return{
      data:{},
      th:['ชื่อสาขา','email','เบอร์โทร','รหัสร้าน','วันที่สร้าง'],
      Index: '',
      nameState: null,
      company: '',
      search: '',


      form : new Form ({
        companyId:'',
        comNameTh : '',
        comPhone : '',
        comEmail : '',
        comCode : '',
      })


    }
  },

  validations: {
    form : {
      comNameTh: {
        required,
        minLength: minLength(3)
      },
      comCode: {
        required
      },
    }

  },
  mounted() {
    this.getResults()
  },
  methods:{
    async getResults(){
       await axios.get('api/company/companyIndex' )
          .then(response => {
            if (response.data.success === true)
            this.data = response.data.data
          }).catch((error)=>{
             if (error.response.status === 500) {
               this.$swal('something wrong', error.response.data.msg, 'error');
             }
           });
    },


     editModal(data,index){
      this.$v.$reset();
      this.Index = index;
      this.form.fill(data);
      this.$bvModal.show('modal-update')

    },
    async update(bvModalEvt){
      bvModalEvt.preventDefault()
      this.$v.$touch()
      if (this.$v.$pending || this.$v.$error) return;
      await this.form.put('api/company/companyUpdate/'+this.form.companyId)
          .then(  (response) => {
            // console.log(response.data.data)
            if (response.data.success === true ){
              this.getResults()
              // this.data.data.splice(this.Index,1,response.data)
              this.$bvModal.hide('modal-update')
            }
          }).catch((error) => {
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
            }
          });


    },

    createModal(){
      this.$v.$reset();
      this.form.reset()
      this.$bvModal.show('modal-create')
    },

    async create(bvModalEvt){
      bvModalEvt.preventDefault()
      this.$v.$touch()
      if (this.$v.$pending || this.$v.$error) return;
      await this.form.post('api/company/companyCreate')
          .then( async response => {
            if (response.data.success === true ){
              // await this.data.data.unshift(response.data.data)
              this.getResults()
              this.$bvModal.hide('modal-create')
            }
          }).catch((error) => {
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
            }
          });
    },

    toast(msg){
      this.$swal({
        position: 'top-end',
        icon: 'success',
        toast: true,
        title: msg,
        showConfirmButton: false,
        timer: 1500
      })
    },


    async deleteModal(data){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be delete this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then( async (result) => {
        if (result.value) {
          await axios.delete('api/company/companyDelete/'+data.companyId)
              .then(async(res) => {
                if (!res.data.success === true){
                  this.$swal({
                    type: 'error',
                    title: 'ข้อมูลไม่ถูกต้อง',
                    text: 'ข้อมูลไม่ถูกต้อง',
                    showConfirmButton: true,
                    timer: 5000
                  })
                }else {
                  // await this.data.data.splice(index,1)
                  this.getResults()
                  this.$swal(
                      'Deleted!',
                      'Your file has been deleted.',
                      'success'
                      )
                  }
                })
        }
      }).catch((err) => {
        this.$swal("Failed!", "There was something wronge", "warning");
        console.log(err)
      })
    },
    async resetPassword(data,index){
      console.log(data,index)
    },

  }
}
</script>
